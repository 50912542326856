/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState, useEffect } from "react"
import axios from "axios"
import { BasicContent, Card, CTA, CTAGroup, ColumnItem, Columns, Layout, Section, SystemStatus } from "../components";

const HomePage = ({ pageContext }) => {
  const args = {
    tooltip:
      "Because Okta's architecture is highly multi-tenant and includes a large set of use cases, products and geographical regions, the availability displayed here is an average of Okta service incidents that affected at least 10% of customers and is not intended to be representative of each customer's specific service availability.",
    cta: {
      label: "View system status",
      link: "https://status.okta.com/",
    },
    subtitle: {
      label: "12-Month Availability:",
      percent: "99.99%",
    },
  }
  const [status, setStatus] = useState(null)

  useEffect(() => {
    axios
      .get("/api/sttus", { timeout: 5000 })
      .then(response => {
        if (response.data && response.data.data && response.data.data.class) {
          // API is accessed, and returns operational status
          setStatus(response.data.data.class.replace("system-status--", ""))
        } else {
          // Catches if API is accesible, but doesn't return a status
          setStatus("operational")
        }
      })
      .catch(() => {
        // Catches if API is down, timedout, or can't be accessed
        setStatus("operational")
      })
  }, [])

  const cards = [
    {
      title: `System Status`,
      body: `<h6>Up-to-the-minute information on service availability</h6>`,
      ctaUrl: "https://status.okta.com",
      ctaText: "View more",
    },
    {
      title: `Security`,
      body: `<h6>Our approach for securing identities</h6>`,
      ctaUrl: "https://trust.okta.com/security/",
      ctaText: "Learn more",
    },
    {
      title: `Compliance`,
      body: `<h6>We can help you meet compliance requirements</h6>`,
      ctaUrl: "https://trust.okta.com/compliance/",
      ctaText: "Learn more",
    },
  ]

  const resources = [
    {
      title: `Report a Vulnerability`,
      body: `<p>Report a security vulnerability in Okta products or services</p>`,
      ctaUrl: "https://www.okta.com/vulnerability-reporting-policy/",
      ctaText: "Learn more",
    },
    {
      title: `Okta Bug Bounty`,
      body: `
        <p>Learn more about scope and rewards at:</p>
        <p><a href="https://bugcrowd.com/okta">https://bugcrowd.com/okta</a></p>
        <p><a href="https://bugcrowd.com/auth0-okta">https://bugcrowd.com/auth0-okta</a></p>`,
    },
    {
      title: `Security Advisories`,
      body: `
        <p>Security Advisory information</p>
        <p><a href="https://trust.okta.com/security-advisories.xml">RSS Feed</a></p>`,
      ctaUrl: "https://trust.okta.com/security-advisories/",
      ctaText: "Learn more",
    },
    {
      title: `Set a Primary Security Contact`,
      body: `<p>The Primary Security Contact may be used to alert your security and privacy team if Okta identifies a specific threat to your organization requiring notification.</p>`,
      ctaUrl: "https://support.okta.com/help/s/article/super-admins-leverage-the-okta-help-center-to-review-and-update-your-companys-primary-security-contact-and-cio-ciso-contact?language=en_US",
      ctaText: "Learn more",
    },
  ]

  return (
    <Layout pageContext={pageContext}>
      <Section
        paddingTop={`small`}
        paddingBottom={`none`}
      >
        <Columns>
          <ColumnItem width={`58.33`}>
            <BasicContent
              title={`Trust starts with transparency.`}
              headerTag={`h2`}
              body={`
                <p>The Okta Trust Page is a hub for real-time information on performance, security, and compliance. Click the links below to access additional content including whitepapers, security-related support documentation, and compliance certifications.</p>
              `}
            />
          </ColumnItem>
          <ColumnItem width={`41.67`}>
            <SystemStatus status={status} {...args} />

          </ColumnItem>
        </Columns>
      </Section>
      <Section
        paddingTop={`none`}
        paddingBottom={`none`}
      >
        <Columns>
          {cards.map((card) => <ColumnItem width="33.33">
            <Card
              title={card.title}
              tag={`h2`}
              body={card.body}
              ctaUrl={card.ctaUrl}
              ctaText={card.ctaText}
            />
          </ColumnItem>)}

        </Columns>
      </Section>
      <Section paddingTop="small" paddingBottom="none">
        <Columns>
          <ColumnItem width={"75"}>
            <BasicContent
              title={`Security Blog`}
              headerTag={`h2`}
              body={`
                      <h4><a href="https://sec.okta.com/blockanonymizers">How to Block Anonymizing Services using Okta</a></h4>

                      <h4><a href="https://sec.okta.com/articles/2024/04/why-cyber-heroes-need-zero-trust-caep">Why Cyber-heroes need a Zero Trust CAEP!</a></h4>

                      <h4><a href="https://sec.okta.com/articles/2024/04/okta-verify-vulnerability-disclosure-report-response-and-remediation">Okta Verify Vulnerability Disclosure Report - Response and Remediation</a></h4>

                      <h4><a href="https://sec.okta.com/articles/2024/04/defensive-domain-registration-mugs-game">Defensive Domain Registration is a Mug’s Game</a></h4>

                    `}
            />
            <div sx={{ pt: "7px" }} />
          </ColumnItem>
          <ColumnItem width={"25"}>
            <CTAGroup
              flexDirection={"row"}
              alignItems={"center"}
            >
              <CTA
                href={"https://sec.okta.com/rss.xml"}
                size={"regular"}
                style={"secondary"}
                text={"Subscribe to the RSS Feed"}
              />
            </CTAGroup>
          </ColumnItem>
        </Columns>
      </Section>
      <Section paddingTop="small" paddingBottom="small" >
        <BasicContent
          title={`Resources`}
          headerTag={`h2`}
        />
        <div sx={{ pt: "7px" }} />
        <Columns horizontalAlignment={"flex-start"} verticalAlignment={"stretch"}>
          {
            resources.map((card) => <ColumnItem width={"25"}>
              <Card
                title={card.title}
                tag={`h4`}
                body={card.body}
                ctaUrl={card.ctaUrl}
                ctaText={card.ctaText}
              />
            </ColumnItem>)
          }
        </Columns>
      </Section>
    </Layout>
  )
}

export default HomePage